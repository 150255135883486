<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Code</label>
            <b-form-select
              id="input-lg"
              value-field="nickname"
              text-field="nickname"
              size="lg"
              v-model="selectNickname"
            >
              <b-form-select-option
                v-for="(proj, i) in this.projectList"
                v-bind:key="i"
                :value="{ code: proj.code, text: proj.nickname }"
              >
                {{ proj.nickname }}
              </b-form-select-option>
            </b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Meeting Code</label>

            <b-form-input v-model="filter.code" size="lg"></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Nickname</label>

            <b-form-input
              v-model="filter.nickname"
              size="lg"
              disabled
            ></b-form-input>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <div class="text-right">
              <button class="btn btn-primary btn-md" @click="getMeetings">
                <i class="flaticon2-search-1"></i> Show
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <complete-table :loading="loading" :data="this.meetings">
          <template #header>
            <tr class="text-left">
              <th style="min-width: 200px" class="pl-7">
                <span class="text-dark-75">Project Code</span>
              </th>
              <th style="min-width: 150px">Nickname</th>
              <th style="min-width: 150px">Meeting Code</th>
              <th style="min-width: 150px">Meeting Date</th>
              <th style="min-width: 150px">Meeting Agenda</th>
              <th style="min-width: 150px">Action</th>
            </tr>
          </template>

          <template #defaultBody="{ item, i }">
            <tr>
              <td>
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.project_code }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.project ? item.project.nickname : "-" }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.code }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ moment(item.date).format("DD-MM-YYYY hh:mm:ss") }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.subject }}
                </span>
              </td>

              <td>
                <button
                  class="btn btn-default btn-xs"
                  @click="toDetailPage(item.id)"
                >
                  <span class="flaticon-eye"></span>
                </button>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import { mapState } from "vuex";
import { arrayMoreThanOne } from "@/core/helper/array-validation.helper";
import { GET_PROJECT_DETAIL } from "@/core/services/store/project.module";
import "jspdf-autotable";
import { GET_MEETING } from "@/core/services/store/meeting.module";
import { showToast } from "@/core/helper/toast.helper";
import {
  SHOW_PROJECT_INTERFACE,
  SHOW_PROJECT_DESIGN,
  SHOW_PROJECT_PLANNER,
  SHOW_PROJECT_DRAFTER,
  SHOW_PROJECT_KOOR,
  SHOW_PROJECT_ENGINER,
  SHOW_PROJECT_MANAGER,
  SHOW_PROJECT_ADMIN,
} from "@/core/services/store/dl.design.module";

export default {
  components: { CompleteTable },
  data() {
    return {
      filter: {
        project_code: null,
        nickname: null,
        code: null,
      },
      selectNickname: {
        code: null,
        text: null,
      },
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.projectDetails.projectDetails?.data,
      scopes: (state) => state.scope.scopes?.data,
      loading: (state) => state.meeting.loading,
      meetings: (state) => state.meeting.meetings?.data,
    }),

    filteredMeeting() {
      return this.meetings?.filter((meeting) => {
        return (
          (this.filter?.code
            ? meeting.project_code === this.filter.code
            : true) &&
          (this.filter?.meeting_code
            ? meeting.code
                .toUpperCase()
                ?.includes(this.filter.meeting_code.toUpperCase())
            : true)
        );
      });
    },

    formattedProjectOptions() {
      let projects = [];

      if (arrayMoreThanOne(this.projects)) {
        projects = this.projects.map((project) => {
          return {
            value: project.code,
            text: `${project.code} - ${project.nickname}`,
          };
        });
      }

      return [{ value: null, text: "Choose Project" }, ...projects];
    },
    projectList() {
      let projectInterface = this.$store.getters.getterProjectInterface;
      let projectDesign = this.$store.getters.getterProjectDesign;
      let projectPlanner = this.$store.getters.getterProjectPlanner;
      let projectDrafter = this.$store.getters.getterProjectDrafter;
      let projectKoor = this.$store.getters.getterProjectKoor;
      let projectEnginer = this.$store.getters.getterProjectEnginer;
      let projectManager = this.$store.getters.getterProjectManager;
      let projectAdmin = this.$store.getters.getterProjectAdmin;

      let combine1 = this.merge(projectInterface, projectDesign, "code");
      let combine2 = this.merge(combine1, projectPlanner, "code");
      let combine3 = this.merge(combine2, projectDrafter, "code");
      let combine4 = this.merge(combine3, projectKoor, "code");
      let combine5 = this.merge(combine4, projectEnginer, "code");
      let combine6 = this.merge(combine5, projectManager, "code");
      let combine7 = this.merge(combine6, projectAdmin, "code");

      return combine7;
    },

    currentProjectCode() {
      return this.selectNickname.code;
    },
  },
  methods: {
    merge(a, b, prop) {
      var reduced = a.filter(
        (aitem) => !b.find((bitem) => aitem[prop] === bitem[prop])
      );
      return reduced.concat(b);
    },
    getProjects() {
      this.$store.dispatch(GET_PROJECT_DETAIL, "interface");
    },

    getReports() {
      Object.assign(this.filter, {
        project_code: this.selectNickname.code,
      });

      this.getMeetings();
    },

    setNickname(value) {
      const project = this.projects?.find((project) => project?.code === value);

      this.filter.nickname = project?.nickname;

      this.filter.revision_version = project?.dmp_rev;
    },

    getMeetings() {
      this.$store
        .dispatch(GET_MEETING, {
          params: {
            ...this.filter,
          },
        })
        .then(() => {
          showToast("Success", "Success get meeting list", "success");
        });
    },

    toDetailPage(id) {
      this.$router.push({ name: "reporting-meeting-detail", params: { id } });
    },
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Reporting Meeting" }]);

    //this.getProjects();
    await this.$store.dispatch(SHOW_PROJECT_INTERFACE);
    await this.$store.dispatch(SHOW_PROJECT_DESIGN);
    await this.$store.dispatch(SHOW_PROJECT_PLANNER);
    await this.$store.dispatch(SHOW_PROJECT_DRAFTER);
    await this.$store.dispatch(SHOW_PROJECT_KOOR);
    await this.$store.dispatch(SHOW_PROJECT_ENGINER);
    await this.$store.dispatch(SHOW_PROJECT_MANAGER);
    await this.$store.dispatch(SHOW_PROJECT_ADMIN);

    this.getMeetings();
  },
  watch: {
    currentProjectCode(value) {
      if (!value) {
        Object.assign(this.filter, {
          project_code: null,
          code: null,
          nickname: null,
        });

        return;
      }

      const project = this.projectList?.find(
        (project) => project?.code === value
      );

      this.filter.project_code = value;
      this.filter.nickname = project?.nickname;
    },
  },
};
</script>
